import React, { useEffect, useState, useRef } from "react";
import { Page, Text, View, Document, Image, StyleSheet, Font, PDFDownloadLink, BlobProvider, pdf } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
// import QRCode from "qrcode";
//import RequestsService from "../../../services/RequestsService";
// import CircularLoading from "../../UX/CircularLoading";
import { Button, Typography } from "@mui/material";
import Saira from "../../../fonts/Saira-Regular.ttf";
import SairaBold from "../../../fonts/Saira-Bold.ttf";
import quotesServices from "../../../services/quotesService";
import axios from "axios";
import QuotePdf from "./quotePDF";
import SweetAlertCreate from "../../UX/Alerts/SweetAlertCreate";
import usersServices from "../../../services/usersService";


const DownloadInternalQuotePDF = ({QuoteInfo = {}, sendEmail = false, clientEmail = '', users = []}) => {
    const [t, i18n] = useTranslation("global");
    const { state } = useLocation();
    const [assets, setAssets] = useState([])
    const qrBaseUrl = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, seterrorMessage]= useState('');
    const [QuoteData, setQuoteData] = useState({});
    const fileRef = useRef(null);
    const [userF, setUser] = useState(null);

    const FormatDate = (date)=>{
        return(`${new Date(date).toLocaleDateString('es', {timeZone: 'America/Mexico_City'})} `)
    }

    const Today = FormatDate(new Date);
    const styles1 = StyleSheet.create({
        page: {
          flexDirection: "column",
          backgroundColor: "#ffffff",
          fontFamily: "Saira",
          paddingHorizontal: 40,
          paddingVertical: 20,
          fontSize: 11,
        },
        row: {
          display: "flex",
          flexDirection: "row",
        },
        col: {
          display: "column",
        },
        rowR: {
          display: "flex",
          flexDirection: "row-reverse",
        },
        alignCenter: { display: "flex", alignItems: "center" },
        tAliRight: {
          textAlign: "right",
        },
        bold: {
          fontWeight: "bold",
        },
        title: {
          fontWeight: "bold",
          fontSize: 24,
        },
        text: {
          fontWeight: "regular",
          fontSize: 24,
        },
        line: {
          backgroundColor: "#d9dadb",
          height: 1,
          marginTop: "15px",
          marginBottom: "10px",
        },
        table: { 
          display: "table", 
          width: "auto", 
          borderStyle: "solid", 
          borderColor:'#d7dbe0',
          borderWidth: 1, 
          borderRightWidth: 0, 
          borderBottomWidth: 0 ,
          borderLeftWidth: 0 
    
        }, 
        tableRow: { 
          flexDirection: "row" 
        }, 
        tableCol: { 
          width: "100%", 
          borderStyle: "solid", 
          borderWidth: 1, 
          borderLeftWidth: 0, 
          borderColor:'#d7dbe0',
          borderTopWidth: 0,
          borderRightWidth: 0, 
          borderBottomWidth: 0,
        }, 
        tableCell: { 
          margin: "auto", 
          marginTop: 0, 
          marginBottom: 0, 
          fontSize: 10 
        }, 
        tableCell1: { 
          marginTop: 0, 
          marginBottom: 0, 
          fontSize: 11,
          fontWeight:'bold'
        },
        tableCell2: { 
          marginTop: 14,
          marginBottom: 4, 
          marginLeft:250,
          fontSize: 10,
          fontWeight:'bold'
        }
    })

    const listOfCountries = [
        {
            ID: 'MX',
            CompanyInvoice: 'SOFTWARE PARA MANTENIMIENTO MP',
            address: 'REFORMA 7, CD BRISA, NAUCALPAN. ESTADO DE MÉXICO, MÉXICO',
            IVALabel: 'IVA 16%',
            format:'EN',
        },
        {
            ID: 'CO',
            IVALabel:'Exluido de IVA*',
            address: 'Calle 34B Nro. 65D-02, Of 201, Medellín-Antioquia, COLOMBIA',
            CompanyInvoice: 'MPSOFTWARE COLOMBIA SAS',
            format:'es'
        },
        {
            ID: 'CL',
            CompanyInvoice: 'MPSOFTWARE CHILE',
            address:'MARCHANT PEREIRA 150 OFICINA 901, COMUNA DE PROVIDENCIA, SANTIAGO, CHILE',
            IVALabel:'IVA 19%',
            format:'es'
        }, 
        {
            ID: '00',
            CompanyInvoice: 'MPSERVICES LLC',
            address:'30 N GOULD ST STE SHERIDAN WY 82801',
            IVALabel:'No incluye Impuestos',
            format:'EN'
        }
    ]

    Font.register({
        family: "Saira",
        fonts: [
            {
            src: Saira,
            },
            {
            src: SairaBold,
            fontWeight: "bold",
            },
        ],
    });

    useEffect(()=> {
      const selectedCountry = listOfCountries.find(country => country.ID == QuoteInfo.country);
      const data = {...QuoteInfo, ...selectedCountry}
      setQuoteData(data)
      data.priceXdiscount = QuoteInfo.discount > 0 ? (QuoteInfo.subTotal * ((100  - QuoteInfo.discount)/100)) : QuoteInfo.subTotal
      console.log('USERS', users)
      console.log('data data data', data)
      const us = users.find(user => user.user_id === data.q_owner)
      console.log('data us us us data data', us)
      setUser(us);

      setIsLoading(false)
    }, [])

    const sendPDF = async() => {
        const formData = new FormData();
        try{
            const blob = await pdf(<QuotePdf QuoteData={QuoteData} user={userF}/>).toBlob();
            formData.append('attach',blob);
            formData.append('client_email',clientEmail);
            formData.append('lan',localStorage.getItem('i18nextLng'));
            const url = process.env?.REACT_APP_LOCAL_ENVIRONMENT === 'TRUE' ?  `http://localhost:3000/quotes/sendQuote` : `${process.env?.REACT_APP_BACK}/quotes/sendQuote`
            const res = await axios.post(url, formData, {
                headers: {
                  'Auth': `${localStorage.getItem('token')}`,
                  'Content-Type': 'multipart/form-data'
                }
            })
            SweetAlertCreate(t('alert.sentEmail'))
        }catch(error){
            console.log('Error', error)
        } 
    }

  return (
    <>
    {!isLoading &&
    (sendEmail ?
        <div>
            <Button sx={{marginTop: '6px'}} variant='contained' size="small" onClick={()=>sendPDF()}>
                <Typography variant="yellowButton">{t("general.send")}</Typography>
            </Button>
        </div>
    :
        <div>
        <PDFDownloadLink
            document={<QuotePdf QuoteData={QuoteData} user={userF}/>}
            fileName={`${t("quote.quote")}-${QuoteData.q_folio}`}
            id="pdfquote"
        >
            {({ blob, url, loading, error }) =>{
            //setPdfLoad(loading)
                return loading ? 
                <Typography>Cargando...</Typography> : 
                <Button sx={{marginTop: '6px'}} size="small" variant="contained"> <Typography variant="yellowButton">{t("general.download")}</Typography></Button>
            }
            }
        </PDFDownloadLink>
        </div>)
    }
</>
  );
};

export default DownloadInternalQuotePDF;