import React, { Fragment, useState, useEffect } from "react";
import {
    BrowserRouter as Router,
    Route,
    BrowserRouter,
    Routes,
    Navigate,
  } from "react-router-dom";
import Protected from "../App/ProtectedComponent";
import PublicQuote from "../App/Quotes/PublicQuote";
import Login from "../App/Auth/login";
import {useSelector} from 'react-redux';
import Quotes from "../App/Quotes/Quotes";
import { Layout } from "./Layouts/Layout";
import NewQuote from "../App/Quotes/NewQuote";
import EditQuote from "../App/Quotes/EditQuote";
import QuoteDetail from "../App/Quotes/QuoteDetail";

const AppRoutes = () => {
    const isUserLoggedIn = useSelector(state => state.authReducer.isLoggedIn)
    const [isLoggedIn, setLog] = useState(isUserLoggedIn)
    const [isLoading, setIsLoading] = useState(true);

    // if(isLoading){
    //     return <>Refrescando Ando</>
    // }

    return(
        <>
        <Routes>
            {(isUserLoggedIn) && (
            <>
            <Route path="/" element={ <Protected isSignedIn={isUserLoggedIn} children={<Layout/>}/> }>
                <Route path='/Quotes' element={ <Protected isSignedIn={isUserLoggedIn} children={<Quotes/>}/>} />
                <Route path='/NewQuote' element={ <Protected isSignedIn={isUserLoggedIn} children={<NewQuote/>}/>} />
                <Route path='/EditQuote/:id' element={ <Protected isSignedIn={isUserLoggedIn} children={<EditQuote/>}/>} />
                <Route path='/QuoteDetail/:id' element={ <Protected isSignedIn={isUserLoggedIn} children={<QuoteDetail/>}/>} />

                <Route path='*' element={<Navigate to={'/Quotes'} />}/>
            </Route>
            </>
            )} 

            {(
            <>
                <Route path='*' element={<Navigate to={"/login"} />}/>
                <Route path="/login"element={<Login autenticated={setLog}/>} />
                <Route path='/publicQuote' element={<PublicQuote />} />
            </>
            )}
            
        </Routes>  
        </>     
    )
}

export default AppRoutes;