
import AuthService from '../../services/authService'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
// export const USERVIEW = 'USERVIEW'
// export const GENERAL_CONFIG = 'GENERAL_CONFIG'


export const login = (data) => dispatch => {
    return AuthService.login(data)
    .then(response => {
        dispatch({
            type: LOGIN, 
            payload:response.data
            })
        return response.data
        })
}

// export const register = (params) => dispatch => {
//     return AuthService.login(params)
//     .then(response => {
//         dispatch(
//             {
//             type: LOGIN,
//             payload:response.data
//             }
//         )
//         })
// }
      
export const logout = () => dispatch => {
     AuthService.logout()
        dispatch(
            {
            type: LOGOUT, 
            }
        )
        }

// export const setUserView = (data) => dispatch => {
//         dispatch(
//             {
//                 type: USERVIEW,
//                 payload: data
//             }
//         )
// }

// export const setGeneralConfig = (data) => dispatch => {
//     dispatch(
//         {
//             type: GENERAL_CONFIG,
//             payload: data
//         }
//     )
// }   