import API from './api'
import HandleError from '../services/HandleError'

const usersServices = {
    fetchAllUsers: ()=>{
        return API.get('users/getAllUsers')
        .then(response => {
            return response.data
        })
        .catch(error => {
            HandleError.HandleError(error)
        })
    },
}

export default usersServices;