import API from './api'
import HandleError from '../services/HandleError'

const quotesServices = {
    fetchAllQuotes: ()=>{
        return API.post('quotes/fecthAllQuotes')
        .then(response => {
            return response.data
        })
        .catch(error => {
            HandleError.HandleError(error)
        })
    },

    createQuote: (data)=>{
        return API.post('quotes/createQuote', data)
        .then(response => {
            console.log('RESPUESTA EN SERVICE', response)
            return response.data
        })
        .catch(error => {
            HandleError.HandleError(error)
        })
    },

    fetchAllQuotes: ()=>{
        return API.post('quotes/fecthAllQuotes')
        .then(response => {
            return response.data
        })
        .catch(error => {
            HandleError.HandleError(error)
        })
    },

    getOneQuote: (data)=>{
        return API.post('quotes/getOneQuote', data)
        .then(response => {
            return response.data
        })
        .catch(error => {
            HandleError.HandleError(error)
        })
    },

    updateQuote: (data)=>{
        return API.post('quotes/updateQuote', data)
        .then(response => {
            console.log('RESPUESTA EN SERVICE', response)
            return response.data
        })
        .catch(error => {
            HandleError.HandleError(error)
        })
    },

    sendQuoteService: (data)=>{
        return API.post('quotes/sendQuote', data)
        .then(response => {
            console.log('RESPUESTA EN SERVICE', response)
            return response.data
        })
        .catch(error => {
            console.log('Errorrrrrr', error)
            HandleError.HandleError(error)
        })
    },

    deleteQuote: (data)=>{
        return API.post('quotes/deleteQuote', data)
        .then(response => {
            console.log('RESPUESTA EN SERVICE', response)
            return response.data
        })
        .catch(error => {
            HandleError.HandleError(error)
        })
    },
}

export default quotesServices;