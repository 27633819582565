import { Page, Text, View, Document, Image, StyleSheet, Font, PDFDownloadLink, BlobProvider, pdf } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import {useTranslation} from "react-i18next";
import  logo from "../../../Assets/img/mptotalxmpsoftware.png";
import mpOffices from "./mptotalOffices";

const QuotePdf = ({QuoteData, user = null}) => {
    const [t, i18n] = useTranslation("global");
    const [office, setOffice] = useState(null)
    const styles1 = StyleSheet.create({
        page: {
          flexDirection: "column",
          backgroundColor: "#ffffff",
          fontFamily: "Saira",
          paddingHorizontal: 40,
          paddingVertical: 20,
          fontSize: 11,
        },
        row: {
          display: "flex",
          flexDirection: "row",
        },
        col: {
          display: "column",
        },
        rowR: {
          display: "flex",
          flexDirection: "row-reverse",
        },
        alignCenter: { display: "flex", alignItems: "center" },
        tAliRight: {
          textAlign: "right",
        },
        bold: {
          fontWeight: "bold",
        },
        title: {
          fontWeight: "bold",
          fontSize: 24,
        },
        text: {
        //   fontWeight: "regular",
          fontSize: 24,
        },
        line: {
          backgroundColor: "#d9dadb",
          height: 1,
          marginTop: "15px",
          marginBottom: "10px",
        },
        table: { 
          display: "table", 
          width: "auto", 
          borderStyle: "solid", 
          borderColor:'#d7dbe0',
          borderWidth: 1, 
          borderRightWidth: 0, 
          borderBottomWidth: 0 ,
          borderLeftWidth: 0 
    
        }, 
        tableRow: { 
          flexDirection: "row",
          borderBottomWidth: 1,
          borderStyle: "solid", 
          borderColor:'#d7dbe0',
        }, 
        tableCol: { 
          width: "100%", 
          borderStyle: "solid", 
          borderWidth: 1, 
          borderLeftWidth: 0, 
          borderColor:'#d7dbe0',
          borderTopWidth: 0,
          borderRightWidth: 0, 
          borderBottomWidth: 0,
        }, 
        tableCell: { 
          margin: "auto", 
          marginTop: 0, 
          marginBottom: 0, 
          fontSize: 10
        }, 
        tableCell1: { 
          marginTop: 0, 
          marginBottom: 0, 
          fontSize: 11,
          fontWeight:'bold'
        },
        tableCell2: { 
          marginTop: 14,
          marginBottom: 4, 
          marginLeft:250,
          fontSize: 10,
          fontWeight:'bold'
        },
        footer:{
            position:'absolute', 
            fontSize: 12, 
            bottom: 20,
            left:0,
            right:0,
            textAlign:'center'
        }
    })

    useEffect(() => {
        if(QuoteData.office){
            const currentOffice = mpOffices.find((con) => con.office_id === QuoteData.office);
            setOffice(currentOffice)
        }
    }, [])

    const FormatDate = (date)=>{
        return(`${new Date(date).toLocaleDateString('es', {timeZone: 'America/Mexico_City'})} `)
    }

    const Today = FormatDate(new Date);
    const getCurrencyFormat = (number = 0) => {
        let format =QuoteData.format
        return number.toLocaleString(format, {
          style: "currency",
          currency: QuoteData.currency,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
    };
    const getPaymentTerms = (pay) => {
        let returnPay = '-'
        switch(pay){
            case '0' : return returnPay = 'Contado'
            case '15' : return returnPay = '15 días'
            case '30' : return returnPay = '30 días'
            case '90' : return returnPay = '90 días'
            case 'endMonth' : return returnPay = 'Al final de mes'
            case 'endNextMont' : return returnPay = 'Al final del siguiente mes'
            default: return returnPay = '-'
        }
    }

    return(
        <Document>
        <Page size="A4" style={[styles1.page, {paddingBottom:110}]}>
        <View>
        <View style={styles1.tableRow}>
            <View style={{...styles1.tableCol, width:'60%'}}>
                <View style={styles1.row}>
                    <Text style={[styles1.title,{fontSize:15}]}>{t("quote.quote").toUpperCase()}</Text>
                </View>
                <View style={styles1.row}>
                    <Text style={[styles1.bold, {fontSize:12 }]}>Folio:</Text>
                    <Text style={[styles1.text, { fontSize:12 }]}> {QuoteData?.q_folio}</Text>
                </View>
                <View style={styles1.row}>
                <Text style={[styles1.bold,{fontSize:12 }]}>{t("quote.createdDate")}:</Text>
                    <Text style={[styles1.text, { fontSize:12 }]}> {FormatDate(QuoteData.createdAt)} </Text>
                </View>
                {/* {user?.user_id &&
                <View style={styles1.row}>
                    <Text style={[styles1.bold, {fontSize:12 }]}>{t("quote.doneBy")}:</Text>
                    <Text style={[styles1.text, { fontSize:12 }]}> {user?.email}</Text>
                </View>
                } */}
            </View>
            <View style={{...styles1.tableCol, width:'40%', alignItems: 'flex-end'}}>
                <Image style={{height: "80", width:'auto'}} src={logo}/>
            </View>
        </View>

         {/* from and to section */}
         <View style={[styles1.tableRow, {paddingTop: '20px', borderBottomWidth: 0}]}> 
            <View style={{...styles1.tableCol, width:'50%'}}>
                <View style={styles1.row}>
                    <Text style={{...styles1.bold}}>{t("general.to")}:</Text>
                </View>
                <View style={styles1.row}>
                    <Text>{QuoteData?.company}</Text>
                </View>
            </View>
            <View style={{...styles1.tableCol, width:'50%'}}>
                <View style={styles1.row}>
                    <Text style={{...styles1.bold}}>{t("general.from")}:</Text>
                </View>
                {office ?
                <>
                <View style={{...styles1.row, wrap: 'no-wrap'}}>
                    <Text>{office?.name}</Text>
                </View>
                <View style={styles1.row}>
                    <Text>{office?.rfc_name}: </Text>
                    <Text>{office?.rfc}</Text>
                </View>
                <View style={styles1.row}>
                    <Text>{office?.addres}</Text>
                </View>
                </> 
                :
                    <View style={{...styles1.row, wrap: 'no-wrap'}}>
                        <Text>{QuoteData.CompanyInvoice}</Text>
                    </View>
                }
            </View>
        </View>
        
        <View style={styles1.line} />
        
        <View style={styles1.table}>
            {/**Header */} 
            <View style={[styles1.tableRow, {borderBottomWidth: 0}]}> 
            <View style={[styles1.tableCol, {width:'65%',  borderRightWidth: 1, backgroundColor:'#993399'}]}> 
            <Text style={[styles1.tableCell,{fontWeight: 'bold', fontSize:11, color: '#FFFFFF' }]}>{t("general.product_des")}</Text> 
            </View> 
            <View style={[styles1.tableCol, {width:'35%', backgroundColor:'#993399'}]}> 
                <Text style={[styles1.tableCell,{fontWeight: 'bold', fontSize:11, color: '#FFFFFF'}]}>{t("general.price")}</Text> 
            </View> 
            </View>
        
            {/* Product Rows */}
            <View style={styles1.cols}>
                {
                QuoteData.products.map((product) => {
                    return(
                    <View style={[styles1.tableRow, {marginTop:5}]}>
                        <View style={[styles1.tableCol, {width:'65%', borderRightWidth: 1, }]}>
                            <Text style={styles1.tableCell1}>
                            {product.name} - {product.quantity} {product.unit}
                            </Text>
                            <Text>
                                {product.description}
                            </Text>
                        </View>
                        <View style={[styles1.tableCol, {width:'35%'}]}>
                            <Text style={[styles1.tableCell,{justifyContent:'flex-end'}]}>
                                {getCurrencyFormat(product.unit_cost[QuoteData.currency]*product.quantity)}
                            </Text>
                        </View>
                    </View>
                    )
                })
                } 
            </View>

            <View style={[styles1.tableRow]}> 
                <View style={[styles1.tableCol, {width:'65%', borderRightWidth: 1 }]}>
                    <Text style={[styles1.tableCell2,{justifyContent:'flex-end'}]}>
                    Subtotal
                    </Text>  
                </View> 
                <View style={[styles1.tableCol, {width:'35%',  marginTop:10,}]}>
                    <Text style={[styles1.tableCell,{justifyContent:'flex-end'}]}>
                        {getCurrencyFormat(QuoteData.subTotal)}
                    </Text> 
                </View> 
            </View> 

            {/* Discount */}
            {QuoteData.discount > 0 && (
            <View style={[styles1.tableRow]}> 
                <View style={[styles1.tableCol, {width:'65%', borderRightWidth: 1 }]}>
                    <Text style={[styles1.tableCell2,{justifyContent:'flex-end'}]}>
                    {t("general.discount")} {QuoteData.discount}%
                    </Text>  
                </View> 
                <View style={[styles1.tableCol, {width:'35%',  marginTop:10,}]}>
                    <Text style={[styles1.tableCell,{justifyContent:'flex-end'}]}>
                       -{getCurrencyFormat(QuoteData.subTotal * (QuoteData.discount/100))}
                    </Text> 
                </View> 
            </View> 
            )} 
            
            {/* Taxes */}
            {QuoteData.currency != 'USD' && (
            <View style={[styles1.tableRow]}> 
                <View style={[styles1.tableCol, {width:'65%', borderRightWidth: 1 }]}>
                    <Text style={[styles1.tableCell2,{justifyContent:'flex-end'}]}>
                    {t("general.taxes")} {QuoteData.tax}%
                    </Text>  
                </View> 
                <View style={[styles1.tableCol, {width:'35%',  marginTop:10,}]}>
                    <Text style={[styles1.tableCell,{justifyContent:'flex-end'}]}>
                        {getCurrencyFormat(( QuoteData.discount > 0 ? (QuoteData.subTotal * ((100  - QuoteData.discount)/100)) : QuoteData.subTotal) * (QuoteData.tax/100))}
                    </Text> 
                </View> 
            </View> 
            )}

            <View style={[styles1.tableRow]}> 
                <View style={[styles1.tableCol, {width:'65%', borderRightWidth: 1 }]}>
                <Text style={[styles1.tableCell2,{marginRight:'flex-end'}]}>
                Total 
                </Text>  
                
                </View> 
                <View style={[styles1.tableCol, {width:'35%',  marginTop:10 }]}>
                    <Text style={[styles1.tableCell,{justifyContent:'flex-end'}]}>
                        {getCurrencyFormat(QuoteData.total)}
                    </Text>   
                </View> 
            </View> 
        </View>

        {/* Termino de pago */}
        <View style={styles1.row}>
            <Text style={[styles1.bold,{fontSize:11, marginTop: 40}]}>{t("general.pay_terms")}:</Text>
            <Text style={{fontSize:11, marginTop: 40}}> {getPaymentTerms(QuoteData.payment)}</Text>
        </View>

        {/* NOTE */}
        <View style={styles1.row}>
            <Text style={[styles1.bold,{fontSize:11, marginTop: 0}]}>{t("quote.note")}:</Text>
            <Text style={{fontSize:11}}> {QuoteData.note}</Text>
        </View>


        {QuoteData.ID=='CO' && (
        <View style={[styles1.row, {marginTop:'10px'}]}>
        <Text style={{fontSize:10}}> *Libre de impuestos según numeral 21 del ART.476 del E.T</Text>
        </View>
        )}

        {QuoteData.currency=='USD' && (
        <View style={styles1.row}>
        <Text style={{fontSize:10}}> * {t("general.price_notaxes")}</Text>
        </View>
        )}
        </View>
        
        </Page>
    </Document>
    )
}

export default QuotePdf;