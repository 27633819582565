import {useState, useEffect} from 'react';
import {useTranslation} from "react-i18next"
import Box from '@mui/material/Box';
import { Button, Grid, Modal, TextField, Typography } from "@mui/material";
import ErrorMessage from '../../UX/errorMessage'
import { PDFDownloadLink, BlobProvider, pdf } from "@react-pdf/renderer";
import axios from "axios";
import QuotePdf from '../Helpers/quotePDF';
import CloseIcon from '@mui/icons-material/Close';
import SweetAlertCreate from '../../UX/Alerts/SweetAlertCreate';

const style = {
  padding:'20px 30px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 390,
  bgcolor: 'background.paper',
  border: '1px solid #000',
};

const ActionPDFModal =({open, setOpen, QuoteData})=> {
    const [t, i18n] = useTranslation("global");
    const [errorMessage, setErrorMessage] = useState('');
    const [quoteData, setQuoteData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    console.log('dATA EN ACTION', QuoteData)

    const listOfCountries = [
        {
            ID: 'MX',
            CompanyInvoice: 'SOFTWARE PARA MANTENIMIENTO MP',
            address: 'REFORMA 7, CD BRISA, NAUCALPAN. ESTADO DE MÉXICO, MÉXICO',
            IVALabel: 'IVA 16%',
            format:'EN',
        },
        {
            ID: 'CO',
            IVALabel:'Exluido de IVA*',
            address: 'Calle 34B Nro. 65D-02, Of 201, Medellín-Antioquia, COLOMBIA',
            CompanyInvoice: 'MPSOFTWARE COLOMBIA SAS',
            format:'es'
        },
        {
            ID: 'CL',
            CompanyInvoice: 'MPSOFTWARE CHILE',
            address:'MARCHANT PEREIRA 150 OFICINA 901, COMUNA DE PROVIDENCIA, SANTIAGO, CHILE',
            IVALabel:'IVA 19%',
            format:'es'
        }, 
        {
            ID: '00',
            CompanyInvoice: 'MPSERVICES LLC',
            address:'30 N GOULD ST STE SHERIDAN WY 82801',
            IVALabel:'No incluye Impuestos',
            format:'EN'
        }
    ]

    useEffect(()=> {
        const selectedCountry = listOfCountries.find(country => country.ID == QuoteData.country);
        const data = {...QuoteData, ...selectedCountry}
        setQuoteData(data);
        // setQuoteData(data)
        // data.priceXdiscount = QuoteInfo.discount > 0 ? (QuoteInfo.subTotal * ((100  - QuoteInfo.discount)/100)) : QuoteInfo.subTotal
        console.log('Data de cotización-->', data);
        setIsLoading(false)
    }, [])

    const sendPDF = async() => {
        const formData = new FormData();
        try{
            const blob = await pdf(<QuotePdf QuoteData={quoteData}/>).toBlob();
            formData.append('attach',blob);
            formData.append('client_email',QuoteData.email);
            formData.append('lan',localStorage.getItem('i18nextLng'));
            const url = process.env?.REACT_APP_LOCAL_ENVIRONMENT === 'TRUE' ?  `http://localhost:3000/quotes/sendQuote` : `${process.env?.REACT_APP_BACK}/quotes/sendQuote`
            const res = await axios.post(url, formData, {
                headers: {
                  'Auth': `${localStorage.getItem('token')}`,
                  'Content-Type': 'multipart/form-data'
                }
            })
            SweetAlertCreate(t('alert.sentEmail'))
            handleClose()
        }catch(error){
            console.log('Error', error)
        } 
    }

    const handleClose = () => {
    setOpen(false)
    };
  
    return (
    <Grid padding='10px'>      
        <Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description" open={open} onClose={handleClose} >
            <Box sx={style}>
            {errorMessage && (
            <ErrorMessage errorMessage={errorMessage} >
            </ErrorMessage> )}

            {/**Close Icon */}
            <Grid container justifyContent='flex-end'>
                <Button>
                    <CloseIcon onClick={handleClose}></CloseIcon>
                </Button>
            </Grid>
            
            {!isLoading && 
            <Grid container spacing={2}>
            
            <Grid item>
            <Button sx={{marginTop: '6px'}} variant='contained' size="small" onClick={()=>sendPDF()}>
                <Typography variant="yellowButton">{t("general.send")}</Typography>
            </Button>
            </Grid>
                
            <Grid item>
            <PDFDownloadLink
                document={<QuotePdf QuoteData={quoteData}/>}
                fileName={`${t("quote.quote")}-${QuoteData.q_folio}`}
                id="pdfquote"
            >
                {({ blob, url, loading, error }) =>{
                return loading ? <Typography>Cargando...</Typography> : <Button sx={{marginTop: '6px'}} size="small" variant="contained"> <Typography variant="yellowButton">Descargar</Typography></Button>
                }
                }
            </PDFDownloadLink>
            </Grid>

            </Grid>
            }
            </Box>
            
        </Modal>
    </Grid>
    )
}

export default ActionPDFModal;