import * as React from 'react';
import {Stack,Alert} from '@mui/material';

const ErrorMessage= ({errorMessage})=> {
  return (

    <Stack sx={{ width:'100%'}} spacing={2}>
    <Alert severity="error">{errorMessage}</Alert>
  </Stack> )
}

export default ErrorMessage;