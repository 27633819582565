const GeneralQuoteHelper = {
    getCurrencyFormat : (amount = 0, curr = 'MXN') => {
        let format = 'EN'
        if(curr === 'CLP' || curr === 'COP'){
            format = 'es'
        }
        return amount.toLocaleString(format, {
          style: "currency",
          currency: curr,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
    }
}

export default GeneralQuoteHelper;