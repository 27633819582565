const HandleError = {

    HandleError:(error)=>{
        console.log(error.response)
        //let err = error
        if(error.response){
            if(error.response.data.message=='Your headers have expired'){
                window.location.href = process.env?.REACT_APP_LOCAL_ENVIRONMENT  === 'TRUE' ? "http://localhost:3001/login" : `${process.env?.REACT_APP_WEB_URL}/login`;
            }
            else{
                throw error.response.data.message     
            }
        }
        else {
            let message= 'Network Error'
            throw message
        }
}
}
export default HandleError