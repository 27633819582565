import * as React from "react";
import { Box, Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const CircularLoading = ({ size }) => {
  return (
    size ? (
      <Box sx={{ display: "flex", margin: "10px"}}>
        <CircularProgress size={size}/>
      </Box>
    ) : (
      <Box sx={{ display: "flex", margin: "10px" }}>
        <CircularProgress />
      </Box>
    )
  )
};

export default CircularLoading;