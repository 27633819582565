import React, { Fragment, useEffect, useState} from "react";
import ReactDOM from 'react-dom';
import {useNavigate, NavLink} from 'react-router-dom'
import { useForm } from "react-hook-form";
import {useTranslation} from "react-i18next"
import {useSelector, useDispatch} from 'react-redux'

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { AlignHorizontalCenter, Input } from "@mui/icons-material";
import { Grid, InputLabel, Link, TextField } from "@mui/material";
import { maxHeight, minHeight } from "@mui/system";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

//import './styles.css'
import logomp from '../../Assets/img/logo-mptotal-snegro-2x-01.png'
import AuthService from "../../services/authService";
import { logout } from "../../store/actions/auth";

let activeStyle = {
  padding:'20px',
  marginTop:50,
  fontFamily:'Saira',
  fontWeight: 600,
  fontSize: '15px',
  fontHeight: '22px',
  textDecoration:'none',
  color:'#993399',
  textTransform: 'uppercase'
};

let navLinkStyle = {
  padding:'20px',
  marginTop:50,
  fontFamily:'Saira',
  fontWeight: 600,
  fontSize: '15px',
  fontHeight: '22px',
  color: '#EDE6E3',
  textDecoration:'none',
  textTransform: 'uppercase'
}



const NavBar = () => {
    const user = useSelector(state => state.authReducer.user)
    const permissions = useSelector(state => state.authReducer.user.permissions)
    const userViews = useSelector(state =>state.authReducer.userViews)
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [language, setLenguage] = useState('es');
    let letter = user.username.charAt(0);
    const [t, i18n] = useTranslation("global");
    const dispatch = useDispatch()

    useEffect(() => {
        setLenguage(localStorage.getItem('i18nextLng'))
    }, [])



    const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    };

    const navigate = useNavigate();

    // const [alignment, setAlignment] = React.useState('');

    const handleChangeLanguage = (event) => {
        setLenguage(event.target.value);
        i18n.changeLanguage(event.target.value);
    };

       
    return(
    <>
    <AppBar sx={{padding: '0px 0px'}} position="relative" color="extraColor2">
      <Container sx={{padding: '0px 0px'}} maxWidth="xl">
        <Toolbar  disableGutters>
          <Typography
            x={{padding: '0px 0px'}}
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
            <img onClick={()=>navigate('/')} src={logomp} style={{padding:'10px 0', maxHeight: '30px', minHeight:'0px'}}></img>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="primary"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
                <MenuItem  onClick={handleCloseNavMenu}>
                    <Button onClick={()=> {navigate("/Quotes")}}>Quotes</Button>
                </MenuItem>
            </Menu>
          </Box>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <img src={logomp} style={{padding:'0', maxHeight: '40px', minHeight:'0px'}}></img>
          </Typography>
          
          <Box sx={{ flexGrow: 1, margin:'30px' ,display: { xs: 'none', md: 'flex' } }}>
            <nav>
                <NavLink to='/Quotes' style={({ isActive }) =>isActive ? activeStyle : navLinkStyle}>
                    {t("quote.quotes")}
                </NavLink>
            </nav>
          </Box>
            
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title= "Open settings">
              <IconButton color="primary" onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar>{letter}</Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '58px'}}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >

            
                  {/* <Typography onClick={()=>{
                    dispatch(logout())
                    redirectLogin()
                    }} textAlign="center">Logout</Typography> */}
                     <Card  sx={{ mt:-1, padding: 0, width: 400, height: '170px', background: '#170312' }}>
                     <CardContent>
                       <Grid container>
                         <Grid item xs={5} sm={5} md={5} lg={5} xl={5} >
                     
                     </Grid>
                     <Grid item xs={4} sm={3} md={4} lg={3} xl={3} >
                     <IconButton  color="primary" onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                     <Avatar sx={{ width: 70, height: 70 }} sizes="large" >{letter}</Avatar>
              
                     </IconButton>
                     </Grid>
                       </Grid>
                       <Typography padding='10px' align="center" color='#E7E2E2'>{user.username}</Typography>
                       <Typography padding='0px' align="center" color='#E7E2E2'>{user.organization}</Typography>
                     </CardContent>
                     </Card>
                     <Card sx={{ mb:-1, width: 400, height: '190px', background: '#FFFFFF'}}>
                       <CardContent>
                        <Grid container direction="column">
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={language}
                                label="language"
                                onChange={handleChangeLanguage}
                            >
                                <MenuItem value={'es'}>Español</MenuItem>
                                <MenuItem value={'en'}>English</MenuItem>
                            </Select>
                            <Button color="error">
                                <Typography variant="caption" onClick={()=>{
                                        dispatch(logout())
                                        navigate(process.env?.REACT_APP_LOCAL_ENVIRONMENT == 'TRUE' ? 'http://localhost:3001/login' : `${process.env?.REACT_APP_WEB_URL}/login`)
                                }} textAlign="center">Logout</Typography> 
                            </Button>
                        </Grid>
                       </CardContent>
                       </Card>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
    </>
    )
}

export default NavBar;