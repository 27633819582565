import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom"
import NavBar from "../../App/UX/NavBar";
import usersServices from "../../services/usersService";


export function Layout () {
  const [isLoading, setIsLoading] = useState(true);
  const [allUsers, setAllUsers] = useState ([]);


  useEffect(() => {
    console.log('Corro useEffect layout')
    try{
      const fetchData = async() => {
          const fetchUsers = await usersServices.fetchAllUsers();
          console.log('usuarios !!!!', fetchUsers)
          setAllUsers(fetchUsers);
          setIsLoading(false)
      }

      fetchData();
    }catch(error){
      console.log('Error in fetch data layout---> ', error)
    }

  }, [])

  return (
    <>
    {!isLoading && (
    <>
      <NavBar position='relative'/>
      <Outlet context={[allUsers]}/>
    </>
    )}
    </>
  )
}