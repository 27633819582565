import { theme } from "./theme";
import AppRoutes from "./Routes/AppRoutes";
import { ThemeProvider } from "@mui/material";
import { LicenseInfo } from "@mui/x-license-pro";
import { BrowserRouter } from "react-router-dom";
import {Provider} from 'react-redux';
import store from '../src/store';

function App() {
  LicenseInfo.setLicenseKey(
    "209ba1d9a33fa3ea79dcf8f370fd695cTz02NTk3OCxFPTE3MTUxNzQ5Mjc3OTksUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
  );
  
  return (
    <>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Provider store={store}>
            <AppRoutes />
          </Provider>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
