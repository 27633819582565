import React, { Fragment, useEffect, useState, useContext } from "react";

import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import {useNavigate } from "react-router-dom"; 
import {useDispatch, useSelector} from 'react-redux'

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Alert, Box, Button, Grid, InputLabel, SpeedDial, SpeedDialAction, Stack, TextField, ThemeProvider } from "@mui/material";

import { theme } from "../../theme";

// import logompwhite from '../../assets/img/logompwhite.jpg';
// import logomp from '../../assets/img/logomp.png';
import logompwhitesinfondo from '../../Assets/img/logo-mptotal-sblanco-2x-01.png';
import CircularLoading from "../UX/CircularLoading";
import {login} from '.././../store/actions/auth'

const Login = ({autenticated})  => {
 
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [isLoading, setIsLoading] = useState(false)

  const [errorMessage, seterrorMessage]=useState('')

  const onSubmit = (data, event) => {
    setIsLoading(true)
    dispatch(login(data))
      .then(()=>{
        autenticated(true);
        navigate("/Quotes")
        event.target.reset()})
      .catch((message)=>{
            if(message){
            seterrorMessage(message);
            console.log(errorMessage)
            setIsLoading(false)
            }
        })
    };




  const AlertError = (errorMessage) => {
    if(!errorMessage==''){
      return(
        <Stack sx={{ width:'100%'}} spacing={2}>
          <Alert severity="error">{errorMessage}</Alert>
        </Stack> )} }
    

  return(

    <ThemeProvider theme={theme}>
      <Fragment>
        <Grid sx={4}>
        </Grid>

        <Container maxWidth='sm' style={{paddin: '10px'}}>  
           <CardActions>
              <Grid
                container
                padding='15% 0 3% 0'
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center">
                  <img src={logompwhitesinfondo} 
                  height='0%' 
                  width='40%'></img>
              </Grid>
            </CardActions>
  
   
            <Card sx={{  height: '100%', width: '100%', background: '#ECECEC'}}>
               <CardContent>
                  <Typography
                   variant="formTitle"  
                   >Login</Typography>
              </CardContent>
            </Card>

            <Card sx={{height: '100%', width: '100%', }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <CardContent>      
                    <TextField 
                        type='email'
                        label="Email*" 
                        variant="standard" 
                        fullWidth name="email"
                        {...register("email", 
                        { required: true })}>
                    </TextField>

                    {errors.email && 
                    <Typography 
                      variant="alertMessages"
                      >
                     "Error de correo"
                    </Typography>}

                      <br/>
                    <TextField 
                      type='password' 
                      label="Password*" 
                      variant="standard" 
                      fullWidth 
                      name="password" 
                      {...register("password", { required: true })}>
                    </TextField>

                    {errors.password && 
                    <Typography 
                      variant="alertMessages"   
                      >
                      "Error de contraseña"
                    </Typography>}

                    <br /> <br />

                    <Typography 
                        variant='linkSecondary' 
                        fontFamily={"Helvetica Neue"} >
                        Contraseña
                    </Typography>

                    {AlertError(errorMessage)}    

                </CardContent>

                <CardActions>
                   <br /><br /> <br />
                   <Grid
                      container
                      spacing={0}
                      direction="column"
                      alignItems="center"
                      justify="center">
                      {isLoading ? <CircularLoading/> : (
                          <Button 
                          type="submit" 
                          color="neutral" 
                          variant="contained">
                          Login
                      </Button>
                      )}
                  
                  </Grid>
                </CardActions>
                <Grid item xs={12}></Grid>
              </form>
            </Card>
    </Container>
</Fragment>
</ThemeProvider>
    )
}


export default Login;