import { Divider, Select, ThemeProvider } from "@mui/material";
import { theme } from "../../theme";
import Saira from "../../fonts/Saira-Regular.ttf";
import SairaBold from "../../fonts/Saira-Bold.ttf";
import products from "./Helpers/products";
import logompwhitesinfondo from '../../Assets/img/logo-mptotal-sblanco-2x-01.png';
import {useTranslation} from "react-i18next"

import "../../styles/styles.css";
import {
  AvatarGroup,
  Paper,
  Avatar,
  Tooltip,
  Box,
  Checkbox,
  Button,
  Grid,
  InputLabel,
  Link,
  SpeedDial,
  SpeedDialAction,
  Stack,
  TextField,
  Typography,
  MenuItem,
} from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
  pdf
} from "@react-pdf/renderer";

import FormTextField from "../../components/FormTextField";
import PDFDocument from "../../components/PDFdoc";
import quotesServices from "../../services/quotesService";
import axios from "axios";
import CircularLoading from "../UX/CircularLoading";
import QuotePdf from "./Helpers/quotePDF";
import ErrorMessage from "../UX/errorMessage";

const boxStyle = {
  padding: "10px",
  display: "flex",
  flexWrap: "wrap",
  flexGrow: 1,
  margin: "10px 10px",

  "& > :not(style)": {
    width: "100%",
    minWidth: 140,
    minHeight: 180,
    height: "100%",
    borderRadius: 3,
  },
};

function PublicQuote() {
  const [Data, setData] = useState(
    {
      type:'ANNUAL',
      version:'BUSINESS',
      company:'Mpservices',
      platform:'MPSERVICES'
    }
  );
  const [currency, setCurrency] = useState("MXN");
  const [IVA, SetIva] = useState(16);
  const [QuoteData, setQuoteData] = useState({
    ID: 'Mexico',
    Curr:'MXN',
    CompanyInvoice: 'SOFTWARE PARA MANTENIMIENTO MP',
    address: 'Reforma 7, Naucalpan. Estado de México',
    Month: 425,
    Year: 4080,
    IVA: 16,
    IVALabel: 'IVA 16%',
    format:'EN'
  });
  const FormatDate = (date)=> (`${new Date(date).toLocaleDateString('es', {timeZone: 'America/Mexico_City'})} `)
  const [pdfLoad, setPdfLoad] = useState(true);
  const [QuoteSended, setQuoteSended] = useState(false);
  const [folio, setFolio] = useState();
  const [sending, setSending] = useState(false);
  const [quoteToprint, setquoteToprint] = useState({});
  const obligatoryFields=['users', 'company', 'email', 'contact'];
  const [errorMessage, setErrorMessage] = useState('');
  const [t, i18n] = useTranslation("global");

  useEffect(()=> {
    i18n.changeLanguage('es');
  }, [])

  const handleChange = (name, value) => {
    setData((prevState) => {
      return { ...prevState, [name]: value };
    });
    if(name === 'version' && value === 'ENTERPRISE'){
      setData((prevState) => {
        return { ...prevState, ['users']: 10 };
      });
    }
    setErrorMessage('');
  };

  const selectProduct = () => {
    return products.find((prod) => prod.language === 'es' && prod.platform === Data.platform && prod.interval === Data.type && prod.license === Data.version);
  }

  const SendData = async()=>{
    let datatosend = {...Data}
    setSending(true);
    let validations = obligatoryFields.every((field) => {
      if (!Data[field]) {
        alert(
          'Falta Información:' + ' ' + field
        );
        return false;
      } else {
        return true;
      }
    });

    if(Data.version === 'ENTERPRISE' && Data.users < 10){
      setErrorMessage('La licencia enterprise require de al menos 10 usuarios')
      validations = false;
    }
    try{
        if(validations){
            const quoteData = {...QuoteData, ...Data}
            const selectedproduct = selectProduct()
            selectedproduct.quantity = quoteData?.users ?? 0;
            const publicQuote = {
                company: quoteData.company,
                contact_name: quoteData.contact,
                email: quoteData.email,
                currency: quoteData.Curr,
                country: quoteData.ID,
                products: [selectedproduct],
                subTotal: selectedproduct.unit_cost[quoteData.Curr] * quoteData.users,
                discount: 0,
                tax: quoteData.IVA,
                total: (selectedproduct.unit_cost[quoteData.Curr] * quoteData.users) * ((QuoteData.IVA/100) + 1),
                payment: '0',
                q_owner: 'f4d1e2a6-c9e8-4f0e-81b6-f48d718d2924',
                CompanyInvoice: quoteData.CompanyInvoice,
                address: quoteData.address
            }
            //Send the data to the internal portal
            const url1 = process.env?.REACT_APP_LOCAL_ENVIRONMENT === 'TRUE' ?  `http://localhost:3000/quotes/createPublicQuote` : `${process.env?.REACT_APP_BACK}/quotes/createPublicQuote`
            const resQuote = await axios.post(url1, {newQuote: publicQuote})
            setFolio(resQuote.data.createdQuote.q_folio);
            //creation of the pdf
            const formData = new FormData();
            setquoteToprint({...publicQuote, q_folio: resQuote.data.createdQuote.q_folio, ID: quoteData.ID});
            const blob = await pdf(<QuotePdf QuoteData={{...publicQuote, q_folio: resQuote.data.createdQuote.q_folio, ID: quoteData.ID}}/>).toBlob();
            formData.append('attach',blob);
            formData.append('client_email',datatosend.email);
            
            const url = process.env?.REACT_APP_LOCAL_ENVIRONMENT === 'TRUE' ?  `http://localhost:3000/quotes/sendPublicQuote` : `${process.env?.REACT_APP_BACK}/quotes/sendPublicQuote`
            const res = await axios.post(url, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            })
            setQuoteSended(true);
            setSending(false);
        }else{
            setQuoteSended(false);
            setSending(false);
        }
    }catch(error){
        console.log('Error', error)
    } 
 
  }

  const listOfCountriesAndPrices = [{
    ID: 'MX',
    Curr:'MXN',
    CompanyInvoice: 'SOFTWARE PARA MANTENIMIENTO MP',
    address: 'REFORMA 7, CD BRISA, NAUCALPAN. ESTADO DE MÉXICO, MÉXICO',
    Month: 425,
    Year: 4080,
    IVA: 16,
    IVALabel: 'IVA 16%',
    format:'EN',
  },
  {
    ID: 'CO',
    Curr:'COP',
    Month: 109000,
    Year: 942400,
    IVA: 0,
    IVALabel:'Exluido de IVA*',
    address: 'Calle 34B Nro. 65D-02, Of 201, Medellín-Antioquia, COLOMBIA',
    CompanyInvoice: 'MPSOFTWARE COLOMBIA SAS',
    format:'es'
  },
  {
    ID: 'CL',
    Curr:'CLP',
    CompanyInvoice: 'MPSOFTWARE CHILE',
    Month: 24300,
    Year: 234030,
    IVA: 19,
    address:'MARCHANT PEREIRA 150 OFICINA 901, COMUNA DE PROVIDENCIA, SANTIAGO, CHILE',
    IVALabel:'IVA 19%',
    format:'es'
  }, 
  {
    ID: '00',
    Curr:'USD',
    CompanyInvoice: 'MPSERVICES LLC',
    Month: 25,
    Year: 240,
    IVA: 0,
    IVALabel:'No incluye Impuestos',
    format:'EN'
  }]

  const handleChangeSelectCountry = (event) => {
    let data = listOfCountriesAndPrices.find(country => country.ID == event.target.value)
    setQuoteData(data)
  }


  const styles1 = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "#ffffff",
      fontFamily: "Saira",
      paddingHorizontal: 40,
      paddingVertical: 20,
      fontSize: 11,
    },
    row: {
      display: "flex",
      flexDirection: "row",
    },
    col: {
      display: "column",
    },
    rowR: {
      display: "flex",
      flexDirection: "row-reverse",
    },
    alignCenter: { display: "flex", alignItems: "center" },
    tAliRight: {
      textAlign: "right",
    },
    bold: {
      fontWeight: "bold",
    },
    title: {
      fontWeight: "bold",
      fontSize: 24,
    },
    text: {
      fontWeight: "regular",
      fontSize: 24,
    },
    line: {
      backgroundColor: "#d9dadb",
      height: 1,
      marginTop: "15px",
      marginBottom: "10px",
    },
    table: { 
      display: "table", 
      width: "auto", 
      borderStyle: "solid", 
      borderColor:'#d7dbe0',
      borderWidth: 1, 
      borderRightWidth: 0, 
      borderBottomWidth: 0 ,
      borderLeftWidth: 0 

    }, 
    tableRow: { 
      flexDirection: "row" 
    }, 
    tableCol: { 
      width: "100%", 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderColor:'#d7dbe0',
      borderTopWidth: 0,
      borderRightWidth: 0, 
      borderBottomWidth: 0,
    }, 
    tableCell: { 
      margin: "auto", 
      marginTop: 4, 
      marginBottom: 4, 
      fontSize: 10 
    }, 
    tableCell1: { 
      marginTop: 10, 
      marginBottom: 4, 
      fontSize: 11,
      fontWeight:'bold'
    },
    tableCell2: { 
      marginTop: 14,
      marginBottom: 4, 
      marginLeft:250,
      fontSize: 10,
      fontWeight:'bold'

    },})

  Font.register({
    family: "Saira",
    fonts: [
      {
        src: Saira,
      },
      {
        src: SairaBold,
        fontWeight: "bold",
      },
    ],
  });

  const getCurrencyFormat = (number = 0) => {
    let format =QuoteData.format
    return number.toLocaleString(format, {
      style: "currency",
      currency: QuoteData.Curr,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };    

  return (
    <ThemeProvider theme={theme}>
      <Grid container marginTop={5}>
        
        <Grid container justifyContent={"center"} direction={'column'} alignItems={'center'}>
            <img src={logompwhitesinfondo} style={{maxHeight: "50px"}}/>
          <Grid item >
          <Typography variant="h5">Obtén tu cotización de inmediato</Typography>
          </Grid>
          <Grid item  justifyContent={"center"}>
          <Typography variant="h6"> Ingresa tus datos y haz clic en OBTENER COTIZACIÓN para descargarla</Typography>
          </Grid>

          {!QuoteSended ?
           ( <Grid container width={'80%'}>
           <Grid  container width={'80%'} flexGrow={1}>
          
            <Box sx={boxStyle}>
              <Paper elevation={0} border="solid 1px">
              {errorMessage && (
              <ErrorMessage errorMessage={errorMessage} /> )}

              <Typography sx={{padding:'10px', fontWeight:'medium', fontSize:15}}>Paso 1: Llena la información de la suscripción</Typography>
              {/* Platform */}
              <Grid container margin="10px 0" display="flex" spacing={1} width="100%">
                <Grid item container xs={4} md={4}margin="auto 0" padding="0px 20px" justifyContent="flex-end">
                    <Typography variant="generalDataFieldName">
                      Plataforma:
                    </Typography>
                  </Grid>
                
                  <Select
                    size='small'
                    sx={{marginLeft:'10px',  width:'210px'}}
                    value={Data.platform}
                    onChange={(e)=>{handleChange('platform', e.target.value)}}
                  >
                    <MenuItem value={"MPSERVICES"}>MPServices</MenuItem>
                    <MenuItem value={"MPINDUSTRIES"}>MPIndustries</MenuItem>
                  </Select>
                </Grid>

              {/* Lincense version */}
              <Grid container margin="10px 0" display="flex" spacing={1} width="100%">
                <Grid item container xs={4} md={4}margin="auto 0" padding="0px 20px" justifyContent="flex-end">
                  <Typography variant="generalDataFieldName">
                    Versión:
                  </Typography>
                </Grid>
              
                <Select
                  size='small'
                  sx={{marginLeft:'10px',  width:'210px'}}
                  value={Data.version}
                  label="Version"
                  onChange={(e)=>{handleChange('version', e.target.value)}}
                >
                  <MenuItem value={"STARTER"}>STARTER</MenuItem>
                  <MenuItem value={"BUSINESS"}>BUSINESS</MenuItem>
                  {Data.platform === 'MPINDUSTRIES' && <MenuItem value={"ENTERPRISE"}>ENTERPRISE</MenuItem>}
                </Select>
              </Grid>

                <FormTextField
                  name="users"
                  label={"Número de usuarios"}
                  value={Data.users}
                  setValueData={handleChange}
                  mdTextField={2}
                  mdlabel={4}
                  type={"number"}
                  helperText="La licencia enterprise require de al menos 10 usuarios"
                  error
                />
               
               {/* Payment type */}
               <Grid container margin="10px 0" display="flex" spacing={1} width="100%">
                <Grid item container xs={4} md={4}margin="auto 0" padding="0px 20px" justifyContent="flex-end">
                    <Typography variant="generalDataFieldName">
                      Tipo:
                    </Typography>
                  </Grid>
                
                  <Select
                    size='small'
                    sx={{marginLeft:'10px',  width:'210px'}}
                    value={Data.type}
                    onChange={(e)=>{handleChange('type', e.target.value)}}
                  >
                    <MenuItem value={"MONTHLY"}>Mensual</MenuItem>
                    <MenuItem value={"ANNUAL"}>Anual</MenuItem>
                  </Select>
                </Grid>
                {/* <FormTextField
                  name="folio"
                  label={"Folio"}
                  value={Data.folio}
                  setValueData={handleChange}
                  mdTextField={3}
                  mdlabel={4}
                  type={"number"}
                /> */}
              </Paper>
            </Box>
            </Grid>
            <Grid  container  width={'80%'}  flexGrow={1}>
            <Box sx={boxStyle}>
              <Paper elevation={0} border="solid 1px">
              <Typography sx={{padding:'10px', fontWeight:'medium', fontSize:15}}>Paso 2: Ingresa la información de la Empresa</Typography>
              <Grid
                  container
                  margin="10px 0"
                  display="flex"
                  spacing={1}
                  width="100%"
                >
                  <Grid
                    item
                    container
                    xs={4}
                    md={4}
                    margin="auto 0"
                   
                    // border='solid 1px orange'
                    padding="0px 20px"
                    justifyContent="flex-end"
                  >
                    <Typography variant="generalDataFieldName">
                      País:
                    </Typography>
                  </Grid>
                 
                  <Select
                  sx={{marginLeft:'10px',  width:'210px'}}
                    size='small'
                    value={QuoteData.ID}
                    label="Moneda"
                    onChange={handleChangeSelectCountry}
                  >
                    <MenuItem value={"MX"}>México</MenuItem>
                    <MenuItem value={"CO"}>Colombia</MenuItem>
                    <MenuItem value={"CL"}>Chile</MenuItem>
                    <MenuItem value={"00"}>Otro (USD)</MenuItem>

                  </Select>
                </Grid>
                <FormTextField
                  name="company"
                  label={"Nombre de la Empresa"}
                  value={Data.comany}
                  setValueData={handleChange}
                  mdTextField={6}
                  mdlabel={4}
                />
                <FormTextField
                  name="contact"
                  label={"Nombre del Contacto"}
                  value={Data.contact}
                  setValueData={handleChange}
                  mdTextField={6}
                  mdlabel={4}
                />
                    <FormTextField
                  name="email"
                  label={"Email del Contacto"}
                  value={Data.email}
                  setValueData={handleChange}
                  mdTextField={6}
                  mdlabel={4}
                />
              </Paper>
            </Box>
            </Grid>
            </Grid>) :
            ( 
              <Grid padding={10}>
                <Typography variant="h6"> Gracias por tu solicitud. se enviará a tu correo la cotización o también puedes descargala. </Typography>
                          
              </Grid>)
                          }

             
        </Grid>
       

        <Grid container justifyContent={"center"}>
            {!QuoteSended ? ( 
            sending ?
            <Button variant='contained' >
                <CircularLoading />
            </Button>
            :
            <Button variant='contained' onClick={()=>SendData()}>
                Obtener Cotización
            </Button>
            ): (
                <PDFDocument name={'COT-'+folio+'-'+Data.company} document={<QuotePdf QuoteData={quoteToprint}/>} setPdfLoad={setPdfLoad} />
            ) }
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default PublicQuote;