const mpOffices = [
    {
        office_id: 'f5d15fa0-1c34-40f0-85d2-6a01682b3d0c',
        name :'SOFTMP S.A. DE C.V,',
        rfc: 'SOF1205315G0',
        rfc_name : 'RFC',
        addres: 'NAUCALPAN DE JUÁREZ, ESTADO DE MÉXICO, MÉXICO',
        currency: 'MXN',
        country: 'MX'
    },
    {   office_id:'cd3bceb8-5fd7-497a-a813-15ac2f7b150f',
        name: 'SOFTWARE PARA MANTENIMIENTO MP S.A. DE C.V',
        rfc: 'SMM12060435A',
        rfc_name : 'RFC',
        addres:'NAUCALPAN DE JUÁREZ, ESTADO DE MÉXICO, MÉXICO',
        currency: 'MXN',
        country: 'MX'
    },
    {   office_id: '065687db-b7d4-4890-8ca2-50ed8d94f6b9',
        name: 'MPSERVICES LLC',
        rfc: '30-1333351',
        rfc_name : 'EIN',
        addres:'BRANDON, FLORIDA, ESTADOS UNIDOS DE AMÉRICA.',
        currency:'USD',
        country: 'US'
    },
    {   office_id: '2813f280-7367-4c14-b828-54adbd494c59', 
        name: 'MPSOFTWARE COLOMBIA S.A.S.',
        rfc: '901257911-7',
        rfc_name : 'NIIT',
        addres:'MEDELLÍN-ANTIOQUIA, COLOMBIA.',
        currency: 'COP',
        country: 'CO'
    },
    {   
        office_id:'b6eac1fa-b784-4e11-93a2-e05fab4c856a',
        name: 'MPSOFTWARE CHILE SpA',
        rfc: '76.607.248- 8',
        rfc_name : 'R.U.T.',
        addres: 'PROVIDENCIA, SANTIAGO DE CHILE, CHILE.',
        Tel: '57 316 027 1310',
        currency: 'CLP',
        country: 'CL'
    }
]

export default mpOffices