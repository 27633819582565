import API from './api'
import HandleError from './HandleError'
import axios from'axios';

const AuthSevice = {
    login: (data)=> {
        console.log('AQUI LLEGO', data)
        return API.post('login', data)
        .then(res => {
            console.log('Respuesta del login', res)
            let user=res.data.user
            let token=res.data.token
            API.defaults.headers['Auth'] = token
            API.defaults.baseURL = process.env?.REACT_APP_LOCAL_ENVIRONMENT === 'TRUE'?  `http://localhost:3000/` : `https://mptotal-internal-03bc39a453f2.herokuapp.com`
            setLocalStorage(user, token)
            return res
        })
        .catch(error => {
            console.log('error diego ', error);
            HandleError.HandleError(error)
        })

    },

//     register: (data)=> {
//         return API.post('/signup',data)
//        .then(response => {
//            return response})
//            .catch(error => {
//             HandleError.HandleError(error)
//         })
//    },

    logout: () =>{
        API.defaults.headers['Authorization']=''
        localStorage.removeItem('user')
        localStorage.removeItem('token')
    }
}

const setLocalStorage = (user , token)=>{
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', token);
    localStorage.setItem('i18nextLng', user.language);
}

export default AuthSevice;
