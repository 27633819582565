const AvatarHelper = {
    /**
     * @description get the letters to render in avatar
     * @param {*} name user's name 
     * @param {*} color color of the avatar 
     * @returns object
     */
    getAvatarProperties: (name = '0', color = '#993399', customWidth = null, customHeight = null, customFontSize = null) => {
        const splitName = name.split(' ');
        const sxObj = {
            bgcolor: color,
            ...(customWidth !== null && {width: customWidth}),
            ...(customHeight !== null && {height: customHeight}),
            ...(customFontSize !== null && {fontSize: customFontSize}),
        }

        return {
            sx: sxObj,
            children: splitName.length < 2 ? `${splitName[0][0]}` : `${splitName[0][0]}${splitName[1][0]}`,
          };
    },
}

export default AvatarHelper;