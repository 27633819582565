import React from "react";
import { PDFDownloadLink, PDFViewer, usePDF } from "@react-pdf/renderer";
import { Button, Typography } from "@mui/material";

const PDFDocument = ({ name, document, setPdfLoad }) => {
  const [instance, updateInstance] = usePDF({ document: document });

  let documentName = `${name}.pdf`;

  return (
    <div>
      <PDFDownloadLink
        document={document}
        fileName={documentName}
        id="pdfWo"
        style={{textDecoration:"none",color: "black"}}
      >
        {({ blob, url, loading, error }) =>{
          setPdfLoad(loading)
          return loading ?  <Typography>Cargando...</Typography>: <Button variant="contained">Descargar</Button>
        }
        }
      </PDFDownloadLink>
    </div>
    // <PDFViewer>
    //    {document}
    // </PDFViewer>
  );
};

export default PDFDocument;
