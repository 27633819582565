const products = [
    {
        id: 1,
        name: 'Suscripción Business Anual MPSERVICES',
        description: 
        `• Incluye Módulo de: activos, clientes, actividades de mantenimiento con plantillas ilimitadas, órdenes de trabajo ilimitadas, inventario, solicitudes de mantenimiento, análisis de información
        • Integraciones
        • Aplicación móvil
        • Centro de aprendizaje
        • Almacenamiento ilimitado
        • Soporte por correo electrónico y llamada`,
        unit: 'Usuario(s)',
        unit_cost:{
            USD: 240,
            CLP: 234030,
            COP: 942400,
            MXN: 4080
        },
        currency:{
            USD: 'EN',
            CLP: 'es',
            COP: 'es',
            MXN: 'EN'
        },
        quantity:0,
        language: 'es',
        platform: 'MPSERVICES',
        interval: 'ANNUAL',
        license: 'BUSINESS'
    },
    {
        id: 2,
        name: 'Suscripción Starter Anual MPSERVICES',
        description: 
        `• Módulo de Activos
        • Módulo de Clientes
        • Módulo de Actividades con plantillas ilimitadas
        • Órdenes de trabajo ilimitadas
        • Análisis de Información limitado
        • Aplicación móvil
        • Centro de aprendizaje
        • Almacenamiento 10gb
        • Soporte por correo electrónico y llamada`,
        unit: 'Usuario(s)',
        unit_cost:{
            USD: 80,
            CLP: 65600,
            COP: 325980,
            MXN: 1360
        },
        quantity:0,
        language: 'es',
        platform: 'MPSERVICES',
        interval: 'ANNUAL',
        license: 'STARTER'
    },
    {
        id: 3,
        name: 'Suscripción Business Mensual MPSERVICES',
        description: `• Incluye Módulo de: activos, clientes, actividades de mantenimiento con plantillas ilimitadas, órdenes de trabajo ilimitadas, inventario, solicitudes de mantenimiento, análisis de información
        • Integraciones
        • Aplicación móvil
        • Centro de aprendizaje
        • Almacenamiento ilimitado
        • Soporte por correo electrónico y llamada`,
        unit: 'Usuario(s)',
        unit_cost:{
            USD: 25,
            CLP: 24300,
            COP: 109000,
            MXN: 425
        },
        quantity:0,
        language: 'es',
        platform: 'MPSERVICES',
        interval: 'MONTHLY',
        license: 'BUSINESS'
    },
    {
        id: 4,
        name: 'Annual Business subscription MPSERVICES',
        description: 
        `• Assets module
        • Clients module
        • Activities module with unlimited templates
        • Inventory module
        • Requests module
        • Unlimited work orders
        • Data analisys
        • Mobile app
        • Knowledge center
        • unlimited storage
        • Priority email support`,
        unit: 'User(s)',
        unit_cost:{
            USD: 240,
            CLP: 234030,
            COP: 942400,
            MXN: 4080
        },
        quantity:0,
        language: 'en',
        platform: 'MPSERVICES',
        interval: 'ANNUAL',
        license: 'BUSINESS'
    },
    {
        id: 5,
        name: 'Annual Starter suscription MPSERVICES',
        description: 
        `• Assets module
        • Clients module
        • Activities module with unlimited templates
        • Unlimited work orders
        • Data analisys (limited)
        • Mobile app
        • Knowledge center
        • unlimited storage
        • Email support`,
        unit: 'User(s)',
        unit_cost:{
            USD: 80,
            CLP: 65600,
            COP: 325980,
            MXN: 1360
        },
        quantity:0,
        language: 'en',
        platform: 'MPSERVICES',
        interval: 'ANNUAL',
        license: 'STARTER'
    },
    {
        id: 6,
        name: 'Monthly Business suscription MPSERVICES',
        description:
        `• Assets module
        • Clients module
        • Activities module with unlimited templates
        • Inventory module
        • Requests module
        • Unlimited work orders
        • Data analisys
        • Mobile app
        • Knowledge center
        • unlimited storage
        • Priority email support`,
        unit: 'User(s)',
        unit_cost:{
            USD: 25,
            CLP: 24300,
            COP: 109000,
            MXN: 425
        },
        quantity:0,
        language: 'en',
        platform: 'MPSERVICES',
        interval: 'MONTHLY',
        license: 'BUSINESS'
    },
    {
        id: 7,
        name: 'Suscripción Starter Anual MPINDUSTRIES',
        description: 
        `•Módulo de Activos
        •Módulo de Actividades con plantillas ilimitadas
        •Módulo de Solicitudes
        •Módulo de Inventario de repuestos
        •Módulo de Proveedores
        •Módulo de Análisis de información
        •Órdenes de trabajo ilimitadas
        •Integraciones (Add On)
        •Aplicación móvil
        •Centro de aprendizaje con videos tutoriales
        •Almacenamiento ilimitado para archivos (fotos, PDF, planos, etc)
        •Soporte prioritario por correo electrónico`,
        unit: 'Usuario(s)',
        unit_cost:{
            USD: 312,
            CLP: 255840,
            COP: 1316640,
            MXN: 5928
        },
        currency:{
            USD: 'EN',
            CLP: 'es',
            COP: 'es',
            MXN: 'EN'
        },
        quantity:0,
        language: 'es',
        platform: 'MPINDUSTRIES',
        interval: 'ANNUAL',
        license: 'STARTER'
    },
    {
        id: 8,
        name: 'Annual Starter suscription MPINDUSTRIES',
        description: 
        `•Assets module
        •Activities module with unlimited templates
        •Requests module
        •Inventory module
        •Vendors module
        •Data analysis 
        •Unlimited work orders
        •API Integration (Add On)
        •Mobile app
        •Knowledge center
        •unlimited storage (fotos, PDF, planos, etc)
        •Priority email support`,
        unit: 'User(s)',
        unit_cost:{
            USD: 312,
            CLP: 255840,
            COP: 1316640,
            MXN: 5928
        },
        currency:{
            USD: 'EN',
            CLP: 'es',
            COP: 'es',
            MXN: 'EN'
        },
        quantity:0,
        language: 'en',
        platform: 'MPINDUSTRIES',
        interval: 'ANNUAL',
        license: 'STARTER'
    },
    {
        id: 9,
        name: 'Suscripción Starter mensual MPINDUSTRIES',
        description: 
        `•Módulo de Activos
        •Módulo de Actividades con plantillas ilimitadas
        •Módulo de Solicitudes
        •Módulo de Inventario de repuestos
        •Módulo de Proveedores
        •Módulo de Análisis de información
        •Órdenes de trabajo ilimitadas
        •Integraciones (Add On)
        •Aplicación móvil
        •Centro de aprendizaje con videos tutoriales
        •Almacenamiento ilimitado para archivos (fotos, PDF, planos, etc)
        •Soporte prioritario por correo electrónico`,
        unit: 'Usuario(s)',
        unit_cost:{
            USD: 33,
            CLP: 27060,
            COP: 1316640,
            MXN: 618
        },
        currency:{
            USD: 'EN',
            CLP: 'es',
            COP: 'es',
            MXN: 'EN'
        },
        quantity:0,
        language: 'es',
        platform: 'MPINDUSTRIES',
        interval: 'MONTHLY',
        license: 'STARTER'
    },
    {
        id: 10,
        name: 'Monthly Starter suscription MPINDUSTRIES',
        description: 
        `•Assets module
        •Activities module with unlimited templates
        •Requests module
        •Inventory module
        •Vendors module
        •Data analysis 
        •Unlimited work orders
        •API Integration (Add On)
        •Mobile app
        •Knowledge center
        •unlimited storage (fotos, PDF, planos, etc)
        •Priority email support`,
        unit: 'User(s)',
        unit_cost:{
            USD: 33,
            CLP: 27060,
            COP: 1316640,
            MXN: 618
        },
        currency:{
            USD: 'EN',
            CLP: 'es',
            COP: 'es',
            MXN: 'EN'
        },
        quantity:0,
        language: 'en',
        platform: 'MPINDUSTRIES',
        interval: 'MONTHLY',
        license: 'STARTER'
    },
    {
        id: 11,
        name: 'Suscripción Business Anual MPINDUSTRIES',
        description: 
        `•Módulo de activos, actividades con plantillas ilimitadas, solicitudes, inventario de repuestos, proveedores, análisis de información, organizaciones (Multiempresa), órdenes de trabajo ilimitadas
        •Aplicación móvil
        •Integraciones
        •Centro de aprendizaje con videos tutoriales
        •Almacenamiento ilimitado para archivos (fotos, PDF, planos, etc)
        •Soporte prioritario por correo electrónico`,
        unit: 'Usuario(s)',
        unit_cost:{
            USD: 420,
            CLP: 344400,
            COP: 1772400,
            MXN: 7980
        },
        currency:{
            USD: 'EN',
            CLP: 'es',
            COP: 'es',
            MXN: 'EN'
        },
        quantity:0,
        language: 'es',
        platform: 'MPINDUSTRIES',
        interval: 'ANNUAL',
        license: 'BUSINESS'
    },
    {
        id: 12,
        name: 'Annual Business suscription MPINDUSTRIES',
        description: 
        `•Assets module
        •Activities module with unlimited templates
        •Requests module
        •Inventory module
        •Vendors module
        •Sites module
        •Data analysis 
        •Unlimited work orders
        •API Integration (Add On)
        •Mobile app
        •Knowledge center
        •unlimited storage (fotos, PDF, planos, etc)
        •Priority email support`,
        unit: 'User(s)',
        unit_cost:{
            USD: 420,
            CLP: 344400,
            COP: 1772400,
            MXN: 7980
        },
        currency:{
            USD: 'EN',
            CLP: 'es',
            COP: 'es',
            MXN: 'EN'
        },
        quantity:0,
        language: 'en',
        platform: 'MPINDUSTRIES',
        interval: 'ANNUAL',
        license: 'BUSINESS'
    },
    {
        id: 13,
        name: 'Suscripción Business mensual MPINDUSTRIES',
        description: 
        `•Módulo de activos, actividades con plantillas ilimitadas, solicitudes, inventario de repuestos, proveedores, análisis de información, organizaciones (Multiempresa), órdenes de trabajo ilimitadas
        •Aplicación móvil
        •Integraciones
        •Centro de aprendizaje con videos tutoriales
        •Almacenamiento ilimitado para archivos (fotos, PDF, planos, etc)
        •Soporte prioritario por correo electrónico`,
        unit: 'Usuario(s)',
        unit_cost:{
            USD: 44,
            CLP: 36080,
            COP: 185680,
            MXN: 830
        },
        currency:{
            USD: 'EN',
            CLP: 'es',
            COP: 'es',
            MXN: 'EN'
        },
        quantity:0,
        language: 'es',
        platform: 'MPINDUSTRIES',
        interval: 'MONTHLY',
        license: 'BUSINESS'
    },
    {
        id: 14,
        name: 'Monthly Business suscription MPINDUSTRIES',
        description: 
        `•Assets module
        •Activities module with unlimited templates
        •Requests module
        •Inventory module
        •Vendors module
        •Sites module
        •Data analysis 
        •Unlimited work orders
        •API Integration (Add On)
        •Mobile app
        •Knowledge center
        •unlimited storage (fotos, PDF, planos, etc)
        •Priority email support`,
        unit: 'User(s)',
        unit_cost:{
            USD: 44,
            CLP: 36080,
            COP: 185680,
            MXN: 830
        },
        currency:{
            USD: 'EN',
            CLP: 'es',
            COP: 'es',
            MXN: 'EN'
        },
        quantity:0,
        language: 'en',
        platform: 'MPINDUSTRIES',
        interval: 'MONTHLY',
        license: 'BUSINESS'
    },
    {
        id: 15,
        name: 'Suscripción Enterprise Anual MPINDUSTRIES',
        description: 
        `•Módulo de Activos, Actividades con plantillas ilimitadas, Solicitudes, Inventario de repuestos, Proveedores, Análisis de información, Módulo de Organizaciones (Multiempresa), Órdenes de trabajo ilimitadas, Integraciones
        •Aplicación móvil
        •Centro de aprendizaje con videos tutoriales
        •Almacenamiento ilimitado para archivos (fotos, PDF, planos, etc)
        •Soporte prioritario por correo electrónico
        •Usuarios técnicos, proveedores y solo lectura ilimitados`,
        unit: 'Usuario(s)',
        unit_cost:{
            USD: 588,
            CLP: 482160,
            COP: 2481360,
            MXN: 11160
        },
        currency:{
            USD: 'EN',
            CLP: 'es',
            COP: 'es',
            MXN: 'EN'
        },
        quantity:0,
        language: 'es',
        platform: 'MPINDUSTRIES',
        interval: 'ANNUAL',
        license: 'ENTERPRISE'
    },
    {
        id: 16,
        name: 'Annual Enterprise suscription MPINDUSTRIES',
        description: 
        `•Assets module
        •Activities module with unlimited templates
        •Requests module
        •Inventory module
        •Vendors module
        •Sites module
        •Data analysis 
        •Unlimited work orders
        •API Integration (Add On)
        •Mobile app
        •Knowledge center
        •unlimited storage (fotos, PDF, planos, etc)
        •Priority email support
        •Unlimited technician, vendors and read only users`,
        unit: 'User(s)',
        unit_cost:{
            USD: 588,
            CLP: 482160,
            COP: 2481360,
            MXN: 11160
        },
        currency:{
            USD: 'EN',
            CLP: 'es',
            COP: 'es',
            MXN: 'EN'
        },
        quantity:0,
        language: 'en',
        platform: 'MPINDUSTRIES',
        interval: 'ANNUAL',
        license: 'ENTERPRISE'
    },
    {
        id: 17,
        name: 'Suscripción Enterprise Mensual MPINDUSTRIES',
        description: 
        `•Módulo de Activos
        •Módulo de Actividades con plantillas ilimitadas
        •Módulo de Solicitudes
        •Módulo de Inventario de repuestos
        •Módulo de Proveedores
        •Módulo de Análisis de información
        •Módulo de Organizaciones (Multiempresa)
        •Órdenes de trabajo ilimitadas
        •Integraciones
        •Aplicación móvil
        •Centro de aprendizaje con videos tutoriales
        •Almacenamiento ilimitado para archivos (fotos, PDF, planos, etc)
        •Soporte prioritario por correo electrónico
        •Usuarios técnicos ilimitados
        •Usuarios proveedores ilimitados
        •Usuarios de solo lectura ilimitados`,
        unit: 'Usuario(s)',
        unit_cost:{
            USD: 62,
            CLP: 50840,
            COP: 261640,
            MXN: 1170
        },
        currency:{
            USD: 'EN',
            CLP: 'es',
            COP: 'es',
            MXN: 'EN'
        },
        quantity:0,
        language: 'es',
        platform: 'MPINDUSTRIES',
        interval: 'MONTHLY',
        license: 'ENTERPRISE'
    },
    {
        id: 18,
        name: 'Monthly Enterprise suscription MPINDUSTRIES',
        description: 
        `•Assets module
        •Activities module with unlimited templates
        •Requests module
        •Inventory module
        •Vendors module
        •Sites module
        •Data analysis 
        •Unlimited work orders
        •API Integration (Add On)
        •Mobile app
        •Knowledge center
        •unlimited storage (fotos, PDF, planos, etc)
        •Priority email support
        •Unlimited technician users
        •Unlimited vendors users
        •Unlimited read only users`,
        unit: 'User(s)',
        unit_cost:{
            USD: 62,
            CLP: 50840,
            COP: 261640,
            MXN: 1170
        },
        currency:{
            USD: 'EN',
            CLP: 'es',
            COP: 'es',
            MXN: 'EN'
        },
        quantity:0,
        language: 'en',
        platform: 'MPINDUSTRIES',
        interval: 'MONTHLY',
        license: 'ENTERPRISE'
    },
    {
        id: 19,
        name: 'Capacitación en línea incluida',
        unit: 'Hora(s)',
        unit_cost:{
            USD: 0,
            CLP: 0,
            COP: 0,
            MXN: 0
        },
        currency:{
            USD: 'EN',
            CLP: 'es',
            COP: 'es',
            MXN: 'EN'
        },
        quantity:0,
        language: 'es'
    },
    {
        id: 20,
        name: 'Training included',
        description: 
        `Online training included`,
        unit: 'Hour(s)',
        unit_cost:{
            USD: 0,
            CLP: 0,
            COP: 0,
            MXN: 0
        },
        currency:{
            USD: 'EN',
            CLP: 'es',
            COP: 'es',
            MXN: 'EN'
        },
        quantity:0,
        language: 'en'
    },
    {
        id: 21,
        name: 'Capacitación',
        description: 
        `Capacitación virtual personalizada`,
        unit: 'Hora(s)',
        unit_cost:{
            USD: 25,
            CLP: 20500,
            COP: 105500,
            MXN: 450
        },
        currency:{
            USD: 'EN',
            CLP: 'es',
            COP: 'es',
            MXN: 'EN'
        },
        quantity:0,
        language: 'es'
    },
    {
        id: 22,
        name: 'Training',
        description: 
        `Online training`,
        unit: 'Hour(s)',
        unit_cost:{
            USD: 25,
            CLP: 20500,
            COP: 105500,
            MXN: 450
        },
        currency:{
            USD: 'EN',
            CLP: 'es',
            COP: 'es',
            MXN: 'EN'
        },
        quantity:0,
        language: 'en'
    },
    {
        id: 23,
        name: 'Suscripción Starter Mensual MPSERVICES',
        description: 
        `• Módulo de Activos
        • Módulo de Clientes
        • Módulo de Actividades con plantillas ilimitadas
        • Órdenes de trabajo ilimitadas
        • Análisis de Información limitado
        • Aplicación móvil
        • Centro de aprendizaje
        • Almacenamiento 10gb
        • Soporte por correo electrónico y llamada`,
        unit: 'Usuario(s)',
        unit_cost:{
            USD: 9,
            CLP: 7380,
            COP: 37986,
            MXN: 155
        },
        quantity:0,
        language: 'es',
        platform: 'MPSERVICES',
        interval: 'MONTHLY',
        license: 'STARTER'
    },
]

export default products;