import { createTheme } from '@mui/material/styles';


export const theme = createTheme({
    typography:{
      fontFamily:'Saira',
      navigationh1: {
        fontFamily:'Saira',
        fontWeight: 600,
        fontSize: '15px',
        fontHeight: '22px',
        color: '#EDE6E3',
      }, 
        MainName: {
          fontFamily:'Saira',
          fontWeight: 600,
          fontSize: '22px',
          fontHeight: '26px',
          color: '#170312',
        },
        subtitle1:{
          fontFamily:'Saira',
          fontWeight: 500,
          fontSize: '17px',
          fontHeight: '26px',
          color: '#170312',
          padding:'7px 10px'
        },
        generalDataFieldName:{
          fontFamily:'Saira',
          fontWeight: 450,
          fontSize: '14px',
          fontHeight: '18px',
          color: '#170312', 
          align:'right',
          display: "flex",
          justifyContent:"flex-end"
        },
        generalDataFieldValue:{
          fontFamily:'Saira',
          fontWeight: 300,
          fontSize: '15px',
          fontHeight: '18px',
          color: '#170312', 
        },
        body1:{
          fontFamily:'Saira',
          fontWeight: 350,
          fontSize: '15px',
          fontHeight: '16px',
          color: '#170312', 
        },
        bodyBig:{
          fontFamily:'Saira',
          fontWeight: 350,
          fontSize: '16px',
          fontHeight: '16px',
          color: '#170312', 
        },
        bodyBigBold:{
          fontFamily:'Saira',
          fontWeight: 500,
          fontSize: '16px',
          fontHeight: '16px',
          color: '#170312', 
        },
        bodyBold:{
          fontFamily:'Saira',
          fontWeight: 500,
          fontSize: '14px',
          fontHeight: '16px',
          color: '#170312', 
        },
        bodyPrimary:{
          fontFamily:'Saira',
          fontWeight: 500,
          fontSize: '14px',
          fontHeight: '15px',
          color: '#993399', 
        },
        bodyCard:{
          fontFamily:'Saira',
          fontWeight: 300,
          fontSize: '14px',
          fontHeight: '15px',
          color: '#170312', 
        },
      linkSecondary:{
        fontFamily:'Saira',
        color: '#531253',
        fontSize: '15px',
        fontHeight: '20px',
        fontWeight: 300,
      },
      buttonText:{
        fontFamily:'Saira',
        color: '#FFFFFF',
        fontSize: '15px',
        fontHeight: '20px',
        fontWeight: 550,
        letterSpacing:'0.07rem'
      },
      form:{
        fontFamily:'Saira',
        color: '#EDE6E3',
        fontWeight: 400,
        fontSize: '15px',
        fontHeight: '20px',
      },
      alertMessages:{
        fontFamily:'Saira',
        color: '#AE5B61',
        fontWeight: 400,
        fontSize: '15px',
        fontHeight: '20px',
      },
      formTitle:{
        fontFamily:'Saira',
        color: '#170312',
        fontWeight: 600,
        fontSize: '22px',
        fontHeight: '26px',
      },
      yellowButton:{
        fontFamily:'Saira',
        color: '#FFFFFF',
        fontWeight: 600,
        fontSize: '13px'
      }
    },
    button:{
      yellowButton: {
        color: '#170312',
      }
    },
    divisor:{
      
    },
    boxDetail:{
      padding:'10px 0px 0px 14px',
          display: 'flex',
          flexWrap: 'wrap',
          '& > :not(style)': {
          width: '96%',
          minWidth:140,
          minHeight: 200,
          height: '105%',
          borderRadius: 3
              },
    },
    status: {
      danger: '#AE5B61',
    },
    palette: {
      primary: {
        main: '#993399',
        darker: '#053e85',
      },
      secondary: {
        main: '#FFFFFF',
        darker: '#993399',
      },
      extraColor1: {
        main: '#531253'
      },
      extraColor2:{
      main: '#170312'
      }, 
      extraColor3: '#847782',
      extraColor4: '#EDE6E3',
      confirmColor: '#531253',
      cancelColor:{
      main: '#AE5B61'
      }, 
      neutral: {
        main: '#993399',
        contrastText: '#fff',
      },
     
    }
  },
  );

